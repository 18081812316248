module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N5PHT3Q","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"ROUTE_CHANGE","enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"880359756618184"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Liyana AI","short_name":"Liyana","start_url":"/app","background_color":"#0C101C","theme_color":"#0C101C","display":"fullscreen","icon":"src/images/icon.png","crossOrigin":"use-credentials","description":"Liyana is a versatile AI writing assistant, offering support in various domains like blogs, business plans, emails, and more.","id":"liyana_ai_v1","categories":["business","education","entertainment","lifestyle","personalization","productivity","social","utilities"],"orientation":"portrait","dir":"ltr","lang":"en","launch_handler":{"client_mode":["navigate-existing","auto"]},"prefer_related_applications":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"f7d2625418f1075a015e309ebcf5e36b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
