const initialState = {
  auth: undefined,
  isLoggedIn: false
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case `LOGIN`:
      return {
        ...state,
        auth: action.data,
        isLoggedIn: true
      }

    default:
      return state
  }
}

export default authReducer
