const initialState = {
  db: {
    profile: undefined,
    usage: undefined,
    subscription: undefined,
    usageHistory: undefined,
    serverTimestamp: undefined,
    subscription: {
      status: undefined,
      plan: {
        wordCount: 2500
      }
    }
  }
}

const dbReducer = (state = initialState, action) => {
  switch (action.type) {
    case `LOAD_USER_DATA`:
      return {
        ...state,
        db: {
          ...state.db,
          ...action.data
        }
      }
    case `UPDATE_USAGE`:
      return {
        ...state,
        db: {
          ...state.db,
          usage: action.data
        }
      }
    case `SET_USAGE_HISTORY`:
      return {
        ...state,
        db: {
          ...state.db,
          usageHistory: action.data
        }
      }
    case `SET_SERVER_TIMESTAMP`:
      return {
        ...state,
        db: {
          ...state.db,
          serverTimestamp: action.data
        }
      }
    case `SET_USER_SUBSCRIPTION`:
      return {
        ...state,
        db: {
          ...state.db,
          subscription: action.data
        }
      }
    default:
      return state
  }
}

export default dbReducer
