// Fonts
import '@fontsource/montserrat/500.css'
import '@fontsource/poppins'

import './src/styles/global.css'
import './src/styles/background.css'
import 'react-toastify/dist/ReactToastify.css'
import './src/styles/navdrawer.scss'
import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'
import 'react-multi-carousel/lib/styles.css'
import 'react-tooltip/dist/react-tooltip.css'

// Firebase
import 'firebase/database'
import 'firebase/auth'

// Set up Redux
import wrapWithProvider from './wrap-with-provider'
export const wrapRootElement = wrapWithProvider
